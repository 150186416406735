@import url("https://fonts.googleapis.com/css?family=Share&display=swap");

body {
    background-color: var(--ss-primary-bg);
    margin: 0;
    background-size: cover;
    color: var(--ss-text-primary);
}

* {
    // color: white;
    // font-family: "Share", sans-serif;
    box-sizing: border-box;
    font-family: var(--ss-font-primary);
    -webkit-tap-highlight-color: transparent;
}

:fullscreen {
    background: black;
}

a {
    cursor: pointer;
}

.ss-card-grid-header {
    font-size: 24px;
    color: var(--ss-text-primary);
    margin-bottom: 1em;
}

.ss-card-wrapper {
    // background: black;
    margin: 4px;
    justify-content: center;
    display: flex;
    align-items: center;
}

@keyframes flash {
    0% {
        clip-path: polygon(0 50%, 100% 50%, 100% 0, 0 0);
    }

    100% {
        clip-path: none;
    }

    // 100% {
    //     clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
    // }
}

.ss-board-view-utility-row {
    position: absolute;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 100;

    .board-tools {
        display: flex;
        justify-content: flex-end;
    }

    .fs-toggle {
        height: 25px;
        width: 224px;
        background: #151515;
        font-size: 18px;
        font-family: "Share";
        text-align: center;
        padding: 10px 0px 6px 0px;
        border-bottom-left-radius: 16px;
        color: #dfe0e2;
        font-weight: 500;
        cursor: pointer;
    }

    .action-toggle {
        background: #1b1b1b;
        height: 40px;
        width: 40px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        &:last-child {
            margin-right: 32px;
        }

        cursor: pointer;
        cursor: pointer;

        .sound-on {
            svg {
                width: 18px;
                transform: translateY(2px);

                path {
                    fill: var(--ss-brand-primary);
                }
            }
        }

        svg {
            width: 18px;
            transform: translateY(2px);

            path {
                fill: #dfe0e2;
            }
        }
    }
}

.ss-board-unlock-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 88px 132px;
    margin-top: 280px;

    @media screen and (max-width: 768px) {
        padding: 96px 40px 48px 40px;
        display: flex;
        margin-top: 192px;
    }

    .ss-board-unlock-content {
        max-width: 300px;
        min-width: 300px;
        transform: translateY(-160px);

        .loader {
            display: flex;
            justify-content: center;
        }

        .ss-auth-header {
            color: var(--ss-text-primary);
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 16px;

            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }

        .auth-sub-header {
            color: var(--ss-colors-off-white);
            font-family: var(--ss-font-roboto);
            line-height: 1.7;
            font-size: 14px;
            font-weight: 100;
            margin-bottom: 16px;
        }

        .ss-auth-form {
            width: 100%;
        }
    }
}

.ss-auth-layout {
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 9rem;
    height: 100vh;

    @media screen and (max-width: 768px) {
        padding-top: 6rem;
    }

    @media screen and (min-height: 1101px) {
        padding-top: 16rem;
    }

    .brand-tag {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 0px 38px 24px 24px;
        z-index: 5000;
        opacity: 0.5;
        .logo {
            display: flex;
            gap: 0.5rem;
            align-items: center;
    
            .text {
                color: var(--ss-text-primary);
                font-size: 12px;
            }
        }
    }

    .brand-text {
        color: var(--ss-text-primary);
        font-size: 1.85rem;
        margin-right: 3em;
        color: var(--ss-colors-off-white);
        font-weight: 800;
        position: absolute;
        top: 40px;
        left: 40px;
    }

    .ss-auth-presentation {
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        max-width: 960px;
        min-height: 640px;
        border-radius: 32px;
        border: 0.8px solid var(--ss-btn-secondary-border);
        box-shadow: 2px 4px 11px #120c22;
        display: flex;
        align-self: start;

        @media screen and (max-width: 768px) {
            border: none;
            box-shadow: none;
            padding: 24px;
            margin: 0;
        }

        .info-card {
            margin: 12px;
            border-radius: 24px;
            background: #110F14;
            flex-basis: 44%;
            padding: 48px 32px 32px 32px;

            @media screen and (max-width: 768px) {
                display: none;
            }

            .title-wrapper {
                display: block;
                margin-bottom: 32px;

                .word-wrapper {
                    position: relative;
                    margin-bottom: 8px;

                    .word-swiper {
                        position: absolute;
                        height: 60px;
                        background: #A8A3D0;
                        display: flex;
                        align-items: center;
                        font-size: 46px;
                        overflow: hidden;
                        flex-wrap: wrap;
                        border-radius: 4px;
                        color: #1E1C31;
                        font-weight: 300;
                        white-space: nowrap;

                        &.one {
                            width: 209px;
                        }

                        &.two {
                            width: 248px;
                        }

                        &.three {
                            width: 168px;
                        }

                        .swipe-text {
                            margin-left: 16px;
                        }
                    }

                    .word {
                        font-size: 53px;
                        font-weight: 300;
                        color: var(--ss-brand-auth-text);
                    }
                }
            }

            .message-wrapper {
                display: block;

                .message {
                    line-height: 1.8;
                    margin-bottom: 24px;
                    font-weight: 300;
                    padding-right: 34px;
                    color: var(--ss-brand-auth-text);
                }
            }
        }

        .auth-area {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            padding-top: 7.25rem;

            @media screen and (max-width: 768px) {
                padding-top: 3.25rem;
            }
        }
    }

    .ss-page-wrapper.auth {
        margin-left: 3rem;
        padding-top: 20rem;
        position: relative;

        @media screen and (max-width: 768px) {
            margin-left: 0rem;
            padding-top: 16rem;
        }
    }
}

.ss-page-wrapper.auth.register {
    padding-top: 13rem;
    position: relative;

    @media screen and (max-width: 768px) {
        margin-left: 0rem;
        padding-top: 12rem;
    }
}

.ss-board-view-wrapper {
    // position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    // align-items: center;
    padding: 88px 132px;

    // background: black;
    @media screen and (max-width: 768px) {
        padding: 96px 40px 48px 40px;
        display: block;
    }

    .ss-board-content-wrapper {
        display: block;

        .ss-board-title-wrapper {
            margin-bottom: 56px;

            @media screen and (max-width: 768px) {
                margin-bottom: 32px;
            }

            .ss-card-wrapper {
                width: 14px;

                @media screen and (max-width: 768px) {
                    width: 8px;
                }

                @media screen and (min-width: 1920px) {
                    width: 16px;
                }

                .ss-letter {
                    font-size: 26px;

                    @media screen and (max-width: 768px) {
                        font-size: 18px;
                    }

                    @media screen and (min-width: 1920px) {
                        font-size: 32px;
                    }
                }
            }
        }

        .ss-board-rows-wrapper {
            display: block;

            .ss-row-group {
                min-height: 114px;
                margin-bottom: 16px;
                display: flex;

                @media screen and (max-width: 768px) {
                    min-height: 56px;
                }

                @media screen and (min-width: 1920px) {
                    min-height: 164px;
                }

                .indicator {
                    width: 16px;
                    background: red;
                    margin-right: 17px;
                    border-radius: 4px;
                    height: 40px;
                    margin-top: 16px;

                    @media screen and (max-width: 768px) {
                        width: 8px;
                        border-radius: 4px;
                        height: 24px;
                        margin-top: 8px;
                    }

                    @media screen and (min-width: 1920px) {
                        height: 69px;
                        margin-top: 19px;
                    }
                }

                .ss-word-wrapper {
                    @media screen and (min-width: 1920px) {
                        margin-right: 2.7ch;
                    }

                    .ss-card-wrapper {
                        width: 26px;

                        @media screen and (max-width: 768px) {
                            width: 10px;
                        }

                        @media screen and (min-width: 1920px) {
                            width: 32px;
                        }

                        .ss-letter {
                            font-size: 48px;

                            @media screen and (max-width: 768px) {
                                font-size: 24px;
                            }

                            @media screen and (min-width: 1920px) {
                                font-size: 72px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.hot-toast-message {
    font-family: "Source Code Pro", monospace;
    font-weight: 100;
    font-size: 14px;
}



// PAGE STRUCTURE

.ss-page-wrapper {
    width: 100%;

    &.auth {
        display: flex;
        justify-content: center;
        padding-top: 16em;

        @media screen and (max-width: 768px) {
            padding-top: 124px;
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}

.page-header-buffer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: var(--ss-primary-bg);
    z-index: 250;
}

.ss-auth-content {
    max-width: 300px;
    min-width: 300px;

    .ss-auth-header {
        color: var(--ss-text-primary);
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 16px;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    .auth-sub-header {
        color: var(--ss-colors-off-white);
        font-family: var(--ss-font-roboto);
        line-height: 1.7;
        font-size: 14px;
        font-weight: 100;
        margin-bottom: 24px;
    }

    .ss-auth-form {
        width: 100%;

        .ss-auth-form-sub-row {
            text-align: center;
            margin-top: 24px;

            .auth-link {
                font-family: var(--ss-font-roboto);
                color: var(--ss-text-secondary);
                font-size: 11px;
                font-weight: 300;
                cursor: pointer;
                text-decoration: underline;
                opacity: 0.8;
            }
        }

        .ss-auth-form-sign-up-row {
            text-align: center;
            margin-top: 52px;
            font-size: 14px;
            font-weight: 300;
            color: var(--ss-text-primary);

            .sign-up-link {
                font-family: var(--ss-font-roboto);
                color: var(--ss-brand-link);
                font-weight: 300;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

//CARDS AND MODALS

.ss-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 156px 32px;
    background: rgb(17 25 41 / 88%);

    @media screen and (max-width: 768px) {
        padding: 96px 0px;
    }

    .modal-container {
        background-color: var(--ss-colors-off-white);
        border-radius: 48px;
        min-width: 384px;
        max-width: 384px;
        height: fit-content;
        padding: 48px 32px;

        .message {
            font-family: var(--ss-font-roboto);
            line-height: 1.7;
            font-size: 16px;
            font-weight: 300;
        }

        .link {
            font-family: var(--ss-font-roboto);
            line-height: 1.7;
            font-size: 15px;
            font-weight: 500;
            color: var(--ss-brand-primary);
        }
    }

    &.danger {
        background: var(--ss-colors-red-darkest);

        .destructive-confirm {
            padding: 32px;
            max-width: 480px;

            .warning-header {
                font-family: var(--ss-font-roboto);
                line-height: 1.7;
                font-size: 26px;
                font-weight: 600;
                color: var(--ss-colors-off-white);
                margin-bottom: 16px;
            }

            .warning-text {
                font-family: var(--ss-font-roboto);
                line-height: 1.7;
                font-size: 24px;
                font-weight: 500;
                color: var(--ss-colors-red-lightest);
            }

            .cancel-text {
                text-align: center;
                margin-top: 32px;
                font-family: var(--ss-font-roboto);
                line-height: 1.7;
                font-size: 16px;
                font-weight: 500;
                color: var(--ss-colors-off-white);
                cursor: pointer;
            }
        }
    }

    &.form {
        background: var(--ss-colors-darks-rock);

        .form-wrapper {
            padding: 32px;
            max-width: 480px;

            .form-header {
                font-family: var(--ss-font-roboto);
                line-height: 1.7;
                font-size: 24px;
                font-weight: 600;
                color: var(--ss-colors-off-white);
            }

            .form-text {
                font-family: var(--ss-font-roboto);
                line-height: 1.7;
                font-size: 14px;
                font-weight: 300;
                color: var(--ss-colors-off-white);
                margin-bottom: 16px;
            }

            .cancel-text {
                text-align: center;
                margin-top: 32px;
                font-family: var(--ss-font-roboto);
                line-height: 1.7;
                font-size: 16px;
                font-weight: 500;
                color: var(--ss-colors-off-white);
                cursor: pointer;
            }

            .ss-input {
                width: 100%;
                min-height: 156px;
            }
        }
    }

    &.solid {
        background: var(--ss-colors-darks-slate);
        animation: slideUp 800ms cubic-bezier(0.75, -0.02, 0.07, 1.26) 0ms 1;

        &.animate-out {
            animation: slideDown 800ms cubic-bezier(0.75, -0.02, 0.07, 1.26) 0ms 1;
        }
    }
}

@keyframes slideUp {
    0% {
        top: 100%;
    }

    100% {
        top: 0;
    }
}

@keyframes slideDown {
    0% {
        top: 0;
    }

    100% {
        top: 100%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.ss-modal-card {
    border-radius: 24px;
    min-width: 384px;
    min-height: 400px;
    padding: 28px 32px;
    // background: #0e0e10;
    // box-shadow: 2px 4px 2px var(--ss-card-shadow);
    align-self: baseline;

    @media screen and (max-width: 768px) {
        min-width: 0px;
        max-width: 100%;
        width: 100%;
        margin: 0px 16px;

        background: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    &.solid {
        background-color: var(--ss-colors-backgrounds-container);
    }
}

//INPUTS

input,
label {
    display: block;
}

textarea {
    resize: none;
}

.ss-form-group {
    margin-bottom: 24px;
    width: 100%;

    label {
        font-size: 13px;
        font-weight: 400;
        color: var(--ss-colors-input-label);
        margin-bottom: 8px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--ss-text-primary);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--ss-input-bg);
}

.ss-input {
    height: 48px;
    padding: 0px 16px;
    background: var(--ss-input-bg);
    border-radius: 8px;
    font-weight: 300;
    font-family: var(--ss-font-roboto);
    color: var(--ss-text-primary);
    border: none;
    box-shadow: 2px 4px 11px #0f0d11;

    &.dark {
        background: var(--ss-colors-darks-rock);
        color: var(--ss-colors-off-white);
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }

    &.expanded {
        width: 100%;
    }

    &::placeholder {
        color: var(--ss-text-placeholder);
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background: #181626; //needs var
        cursor: not-allowed;
    }

    &.t-area {
        padding: 16px;
    }

    &.list-search {
        height: 28px;
        margin-bottom: 9px;
        border-radius: 50px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}

.list-search-wrapper {
    position: relative;
    opacity: 0;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .list-search-clear-chip {
        position: absolute;
        top: 6px;
        right: 8px;
        background: #5a5177;
        height: 16px;
        width: 16px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            height: 8px;
            transform: translateY(-1px);

            path {
                fill: #1e1c31; // needs var
            }
        }
    }
}

.ss-primary-btn {
    background: var(--ss-btn-primary);
    color: var(--ss-colors-off-white);
    min-height: 48px;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    &.danger {
        background: var(--ss-colors-red);
    }

    &.warning {
        background: var(--ss-colors-red);
    }

    &.black {
        background: var(--ss-colors-background);
    }

    &.short {
        width: 88px;
        margin-left: 24px;
        font-size: 16px;
    }

    &.shorter {
        width: 152px;
        margin-left: 24px;
        font-size: 16px;
    }

    svg {
        height: 18px;
        margin-right: 8px;
        transform: translateY(1px);
    }

    &.margin {
        margin-bottom: 24px;
    }
}

.ss-primary-btn[disabled] {
    cursor: not-allowed;
    color: var(--ss-colors-greys-ash);
}

.ss-secondary-btn {
    background: transparent;
    color: var(--ss-colors-off-white);
    min-height: 48px;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--ss-brand-primary);
    cursor: pointer;

    &.o-auth {
        justify-content: center;
        color: var(--ss-text-primary);
        padding: 0px 24px;
        border-color: var(--ss-btn-secondary-border);
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 300;
    }

    svg {
        height: 18px;
        margin-right: 16px;
        transform: translateY(1px);
    }

    &.margin {
        margin-bottom: 24px;
    }
}

.ss-secondary-btn[disabled] {
    border-color: var(--ss-colors-darks-da-blues);
    color: var(--ss-colors-greys-ash);
}

//SWITCH

.ss-switch {
    margin: 0px;
}

.ss-switch input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.ss-switch input[type="checkbox"]:checked+label {
    background: var(--ss-brand-primary);
}

.ss-switch input[type="checkbox"]:checked+label::after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
}

.ss-switch label {
    cursor: pointer;
    width: 48px;
    height: 24px;
    background: var(--ss-colors-greys-cement);
    display: block;
    border-radius: 24px;
    position: relative;
}

.ss-switch label::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 16px;
    transition: 0.3s;
}

//LOADING

.ss-spinner {
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 3px solid var(--ss-colors-greys-ash);
    border-radius: 50%;
    border-top-color: var(--ss-brand-primary);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;

    &.small {
        width: 18px;
        height: 18px;
        border-width: 2px;
    }

    &.passive {
        border-top-color: var(--ss-text-secondary);
    }

    &.danger {
        border-top-color: #ff0083; // needs var
    }

    &.page {
        border-color: #1e1e3e; // needs var
        border-top-color: #372b65; // needs var
        box-shadow: 0px 0px 11px #3a1a7a; // needs var
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

hr {
    border-color: transparent;
    border-top-color: var(--ss-hr-primary);
}

.ss-legal-page-wrapper {
    height: 100%;
    max-width: 1280px;
    margin: 32px auto;
    padding: 32px;
    color: var(--ss-colors-off-white);
    margin-top: 8rem;

    &.legal {
        h1 {
            font-size: 24px;
            margin: 16px 0px 16px 0px;
            color: var(--ss-colors-off-white);
        }

        h2 {
            font-size: 18px;
            font-weight: 600;
            margin: 16px 0px 8px 0px;
            color: var(--ss-colors-off-white);
        }

        p,
        li {
            color: var(--ss-colors-off-white);
        }
    }
}

.ss-terms-prompt-wrapper {
    color: var(--ss-text-secondary);
    margin-top: 24px;
    font-size: 11px;
    text-align: center;
    opacity: 0.8;
    font-weight: 300;

    a {
        color: var(--ss-text-secondary);
        font-weight: 500;
        opacity: 0.8;
    }
}

.eq-bar {
    background-color: #e6e6e6;
    width: 2px;
    transform: translate(5px, 6px);
}

.trial-prompt {
    color: var(--ss-colors-empty-state-text);
    max-width: 320px;
    margin: 0 auto;
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 41px;
    border-radius: 10px;
    border: 1px solid var(--ss-colors-yellow);
    font-size: 14px;
    gap: 0.25em;
    opacity: 0;
    cursor: pointer;
    animation: fadeIn 500ms ease-in;
    animation-fill-mode: forwards;
    flex-wrap: wrap;
    padding: 10px;

}

//HELPERS

.mb0 {
    margin-bottom: 0px;
}

.mb8 {
    margin-bottom: 8px;
}

.mb16 {
    margin-bottom: 16px !important;
}

.mb24 {
    margin-bottom: 24px !important;
}

.mb32 {
    margin-bottom: 32px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb48 {
    margin-bottom: 48px;
}

.mb56 {
    margin-bottom: 56px;
}

.mt0 {
    margin-top: 0px;
}

.mt8 {
    margin-top: 8px;
}

.mt16 {
    margin-top: 16px;
}

.mt24 {
    margin-top: 24px !important;
}

.mt32 {
    margin-top: 32px;
}

.p16 {
    padding: 16px;
}

.p24 {
    padding: 24px;
}

.p32 {
    padding: 32px;
}

.mh156 {
    min-height: 156px !important;
}

.mh256 {
    min-height: 256px !important;
}

.w100p {
    width: 100%;
}