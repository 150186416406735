:root {

    --ss-brand-primary: #5620ca;
    --ss-brand-link: #BC9DFF;
    --ss-brand-auth-text: #A8A3D0;

    --ss-colors-orange-darkest: #4c1600;
    --ss-colors-orange-darker: #8a3718;
    --ss-colors-orange: #EB5216;

    --ss-colors-green-darkest: #013C30;
    --ss-colors-green-dark: #027961;
    --ss-colors-green-darker: #03B591;
    --ss-colors-green: #03CEA4;


    --ss-colors-blue: #279AF1;

    --ss-colors-red-darkest: #3D0000;
    --ss-colors-red-darker: #660000;
    --ss-colors-red: #FF4747;
    --ss-colors-red-lightest: #FFADAD;

    --ss-colors-yellow: #ba9212; // USE
    --ss-colors-yellow-super-light: #EADFCE; 



    --ss-colors-greys-ash: #E5E5E5;
    --ss-colors-greys-cement: #8e99af;

    --ss-colors-background: #09080b;

    --ss-colors-background-darker: #070f22;
    --ss-colors-backgrounds-container: #12161C;
    
    --ss-colors-darks-slate: #0f1a35; // #1C232B
    --ss-colors-darks-da-blues: #2b3c52;
    --ss-colors-darks-cave: #0B0F12;
    --ss-colors-darks-rock: #16264d;

    --ss-colors-tint-white: #F9F2EF;

    //STRICT COLORS

    --ss-colors-input-label: #EFF9F0;
    --ss-colors-input-border: #3F3632;
    --ss-colors-input-placeholder: #666; // LIKELY DEPRECATE
    --ss-colors-input-background: #f4f6f9;

    --ss-colors-borders-primary: #2b3245;

    --ss-colors-empty-state-text: #9f9888;

    //FONTS

    --ss-font-primary: 'Roboto', sans-serif;
    --ss-font-secondary: 'Bai Jamjuree', sans-serif;
    --ss-font-roboto: 'Roboto', sans-serif;


    // NEW VARIABLES VET THE ABOVE AFTER ALL NEW ARE IN PLACE

    --ss-primary-bg: #09080b;
    --ss-primary-nav-bg: #2C2B2D;
    --ss-sub-nav-link: #F7FBFC;
    --ss-sub-nav-link-active: #5620ca;


    --ss-card-bg: #09080b;
    --ss-card-border: #220c4b;
    --ss-card-shadow: rgb(6 1 16);

    --ss-panel-bg: #121217; // PREV 131317 0e0e10

    --ss-input-bg: #1e1c31;

    // VETTED COLORS

    --ss-colors-yellow-200: #C5DB45;
    --ss-colors-yellow-300: #DAFF5F;

    // SONG COLORS NEW -------------------------------------------

    --ss-hr-primary: #5f4a87;

    //BTN
    --ss-btn-primary: #5620ca;
    --ss-btn-secondary-border: #513c8c;

    //TEXT
    --ss-text-primary: #fcfcfcfc;
    --ss-text-secondary: #9FA5B3;
    --ss-text-placeholder: #798196;

    //COLORS
    --ss-colors-off-white: #f7fbfc;

}